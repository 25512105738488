import { render, staticRenderFns } from "./ENVIROMENT.vue?vue&type=template&id=254d722a"
import script from "./ENVIROMENT.vue?vue&type=script&lang=js"
export * from "./ENVIROMENT.vue?vue&type=script&lang=js"
import style0 from "./ENVIROMENT.vue?vue&type=style&index=0&id=254d722a&prod&lang=scss&scope=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports