<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex justify-center my-2">
      <v-overlay absolute :value="loading">
        <v-progress-circular indeterminate size="34"></v-progress-circular>
      </v-overlay>
      <v-pagination
        v-model="windowsstep"
        :length="windowslength"
        :total-visible="7"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
      ></v-pagination>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="my-2"
            v-on="on"
            color="info"
            outlined
            small
            @click="openDialogAdd"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </template>
        <span>Add New Form Config</span>
      </v-tooltip>
      <template v-if="!exists">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="success"
              v-on="on"
              outlined
              class="my-2 mx-1"
              small
              @click="saveConfig"
              ><v-icon>mdi-content-save-settings</v-icon></v-btn
            >
          </template>
          <span>Save Number of Forms</span>
        </v-tooltip>
      </template>
      <template v-else>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="warning"
              v-on="on"
              outlined
              class="my-2 mx-1"
              small
              @click="updateConfig"
              ><v-icon>mdi-content-save-settings</v-icon></v-btn
            >
          </template>
          <span>Update Number of Forms</span>
        </v-tooltip>
      </template>

      <v-dialog
        v-model="dialogconfirmadd"
        persistent
        :overlay="false"
        max-width="300px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title primary-title class="info white--text text-center">
            Add Form Config
          </v-card-title>
          <v-card-text class="text-center mt-4"> Are you sure? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="cancelDialogAdd" text>Cancel</v-btn>
            <v-btn color="success" @click="addFormConfig" text>Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <template v-if="!loading">
      <v-window v-model="windowsstep">
        <v-window-item v-for="n in windowslength" :key="n" :value="n">
          <form-config :indexFormconfig="n" />
        </v-window-item>
      </v-window>
    </template>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import FormConfig from "./FormConfig.vue";
import { notifyError, notifySuccess } from "@/components/Notification";
import { mapActions, mapState } from "vuex";
export default {
  name: "config-form-view",
  components: {
    FormConfig,
  },
  data() {
    return {
      exists: true,
      loading: false,
      dialogconfirmadd: false,
      windowsstep: 1,
      windowslength: 3,
    };
  },
  computed: {
    ...mapState(["configFormTotal"]),
  },
  methods: {
    ...mapActions(["actUpdateSettings"]),
    openDialogAdd() {
      this.dialogconfirmadd = true;
    },
    cancelDialogAdd() {
      this.dialogconfirmadd = false;
    },
    addFormConfig() {
      this.windowslength++;
      this.cancelDialogAdd();
      this.windowsstep = this.windowslength;
    },

    saveConfig() {
      const settings = {
        name: `form_config-count`,
        description: `Count Forms`,
        value: `count-${this.windowslength}`,
        isPublic: true,
      };
      this.loading = true;
      getAPI
        .post("/setting/create", settings)
        .then(() => {
          notifySuccess("Form Configurarions has been save");
          this.exist = true;
          this.loading = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    async updateConfig() {
      const settings = {
        active: this.configFormTotal.active,
        name: this.configFormTotal.name,
        description: this.configFormTotal.description,
        value: `count-${this.windowslength}`,
        isPublic: this.configFormTotal.isPublic,
        uuid: this.configFormTotal.uuid,
      };
      this.loading = true;

      await this.actUpdateSettings(settings);
      this.loading = false;
    },

    init() {
      if (this.configFormTotal != null) {
        const count = Number(this.configFormTotal.value.split("-")[1]);
        this.windowslength = count;
      } else {
        this.windowslength = 3;
        this.exists = false;
      }
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>
<style lang=""></style>
