<template>
  <v-card>
    <v-card-title class="d-flex justify-end">
      <div class="col-sm-4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :search="search"
        dense
        :headers="headersSettings"
        :items="settings"
        :expanded.sync="expanded"
        @click:row="expandRow"
        :loading="loading"
        item-key="uuid"
        class="elevation-1"
      >
        <template v-slot:[`item.active`]="{ item }">
          <div :class="item.active ? 'green--text' : 'red--text'">
            {{ item.active ? "YES" : "NO" }}
          </div>
        </template>
        <template v-slot:[`item.isPublic`]="{ item }">
          <div :class="item.isPublic ? 'green--text' : 'red--text'">
            {{ item.isPublic ? "YES" : "NO" }}
          </div>
        </template>

        <template v-slot:expanded-item="{ item }">
          <v-row class="my-5 mx-2 prueba">
            <v-col class="contexp" cols="12" sm="10">
              <ul>
                <li>
                  <span> <strong>name:</strong> </span>
                  {{ item.name }}
                </li>
                <li>
                  <span> <strong>description:</strong> </span>
                  {{ item.description }}
                </li>
                <li>
                  <span> <strong>value:</strong> </span>
                  {{ item.value }}
                </li>
              </ul>
            </v-col>
            <v-col cols="12" sm="2">
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="cyan"
                @click="editItem(item)"
              >
                <v-icon dark> mdi-pencil </v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="error"
                @click="deleteItem(item)"
              >
                <v-icon dark> mdi-delete </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>Are you sure you want to delete this item? </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-top-transition"
      max-width="400"
      v-model="editSettings"
    >
      <template>
        <v-card>
          <v-toolbar color="primary" dark>Edit Enviroment var</v-toolbar>
          <v-card-text>
            <v-row class="mt-2">
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  v-model="itemSelect.name"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Value"
                  v-model="itemSelect.value"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Description"
                  v-model="itemSelect.description"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-checkbox
                  label="Active"
                  v-model="itemSelect.active"
                ></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  label="Public"
                  v-model="itemSelect.isPublic"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text class="primary" @click="editConfirm()">Save</v-btn>
            <v-btn text @click="editSettings = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-card-actions>
      <v-layout row wrap justify-end mx-4 my-2>
        <v-flex shrink>
          <v-dialog
            transition="dialog-top-transition"
            max-width="400"
            v-model="dialogSettings"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on">Add Env</v-btn>
            </template>
            <template>
              <v-card>
                <v-toolbar color="primary" dark>Add Enviroment var</v-toolbar>
                <v-card-text>
                  <v-row class="mt-2">
                    <v-col cols="12">
                      <v-text-field
                        label="Name"
                        v-model="newSettings.name"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Value"
                        v-model="newSettings.value"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Description"
                        v-model="newSettings.description"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text class="primary" @click="AddEnv()">Save</v-btn>
                  <v-btn text @click="dialogSettings = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data: () => ({
    search: "",
    expanded: [],
    loading: false,
    itemSelect: {},
    headersSettings: [
      {
        text: "NAME",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "DESCRIPTION", value: "description", sortable: false },
      { text: "PUBLIC", value: "isPublic", sortable: false },
      { text: "ACTIVE", value: "active", sortable: false },
    ],
    newSettings: {
      name: "",
      value: "",
      description: "",
    },
    dialogSettings: false,
    dialogDelete: false,
    editSettings: false,
  }),
  computed: {
    ...mapState(["settings"]),
  },
  mounted() {
    this.actSettings();
  },
  methods: {
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },

    ...mapActions([
      "actSettings",
      "actAddSettings",
      "actDeleteSettings",
      "actUpdateSettings",
    ]),
    async AddEnv() {
      this.loading = true;
      await this.actAddSettings(this.newSettings);
      this.loading = false;
      this.close();
    },

    editItem(item) {
      this.itemSelect = item;
      this.editSettings = true;
    },
    async editConfirm() {
      this.loading = true;
      await this.actUpdateSettings(this.itemSelect);
      this.loading = false;
      this.close();
    },

    deleteItem(item) {
      this.itemSelect = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.loading = true;
      await this.actDeleteSettings(this.itemSelect.uuid);
      this.loading = false;
      this.close();
    },
    close() {
      this.itemSelect = {};
      this.dialogDelete = false;
      this.editSettings = false;
      this.dialogSettings = false;
    },
  },
};
</script>
<style lang="scss" scope>
.prueba {
  width: 95vh !important;
}

li {
  list-style: none;
}
ul {
  width: 100%;
}
</style>
