<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex mx-4 mt-3">
      <span>{{
        exist && formConfig != null
          ? formConfig.name
          : "form_config-" + indexFormconfig
      }}</span>
      <v-spacer></v-spacer>
      <template>
        <v-text-field
          label="link"
          class="selectsource"
          v-model="url"
          outlined
          dense
        ></v-text-field>
        <v-select
          :items="references"
          outlined
          clearable
          dense
          class="selectsource mx-1"
          item-text="name"
          item-value="name"
          v-model="formValues.sourceDefault"
          label="Source"
        ></v-select>
      </template>
      <template v-if="exist">
        <v-btn color="warning" @click="updateForm()">Update</v-btn>
        <v-btn
          color="info"
          class="ml-1"
          :disabled="isDefault"
          @click="useForm()"
          >Default</v-btn
        >
      </template>

      <v-btn color="error" class="mx-1" @click="dialogCancel = true"
        >reset</v-btn
      >
      <template v-if="!exist">
        <v-btn color="success" @click="dialogSave = true">save</v-btn>
      </template>
    </div>
    <v-dialog
      v-model="dialogSave"
      persistent
      :overlay="false"
      max-width="300px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="green darken-1 white--text" primary-title>
          Save
        </v-card-title>
        <v-card-text class="my-4 text-center"> save all changes? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogSave = false">close</v-btn>
          <v-btn text color="success" @click="saveNewForm">confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCancel"
      persistent
      :overlay="false"
      max-width="300px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="red darken-1 white--text" primary-title>
          Reset
        </v-card-title>
        <v-card-text class="my-4 text-center"> Reset all changes? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogCancel = false">close</v-btn>
          <v-btn text color="error" @click="resetForm">reset</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="d-flex flex-column form_lead">
      <template>
        <v-form ref="registerForm">
          <div class="field">
            <v-text-field rounded label="Name" outlined dense></v-text-field>
          </div>
          <div class="field">
            <v-text-field
              rounded
              label="Last Name"
              outlined
              dense
            ></v-text-field>
          </div>
          <v-row class="field">
            <v-col cols="12" sm="4">
              <v-text-field
                rounded
                prepend-inner-icon="mdi-numeric-positive-1"
                label="Phone"
                outlined
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="4">
              <v-text-field rounded label="Email" outlined dense></v-text-field
            ></v-col>
            <template v-if="formValues.gender">
              <v-col cols="12" sm="4">
                <div class="d-flex justify-center genderfield">
                  <template v-if="!formValues.gender.active">
                    <v-icon
                      @click="formValues.gender.active = true"
                      color="error"
                      >mdi-close</v-icon
                    >
                  </template>
                  <template v-else>
                    <v-icon
                      @click="formValues.gender.active = false"
                      color="success"
                      >mdi-check</v-icon
                    >
                    <v-icon @click="toEdit(formValues.gender)" color="info"
                      >mdi-pencil</v-icon
                    >
                  </template>
                </div>

                <v-radio-group class="my-2 d-flex justify-center" row>
                  <v-radio
                    :label="formValues.gender.label"
                    :value="false"
                  ></v-radio>
                  <v-radio
                    :label="formValues.gender.label1"
                    :value="true"
                  ></v-radio> </v-radio-group
              ></v-col>
            </template>
          </v-row>
          <v-row class="field">
            <template v-if="formValues.dob">
              <v-col cols="12" sm="3" class="d-flex">
                <template v-if="!formValues.dob.active">
                  <v-icon
                    class="ma-1"
                    @click="formValues.dob.active = true"
                    color="error"
                    >mdi-close</v-icon
                  >
                </template>
                <template v-else>
                  <v-icon
                    class="ma-1"
                    @click="formValues.dob.active = false"
                    color="success"
                    >mdi-check</v-icon
                  >
                  <v-icon
                    class="ma-1"
                    @click="toEdit(formValues.dob)"
                    color="info"
                    >mdi-pencil</v-icon
                  >
                </template>
                <ma-date-picker
                  past
                  :label="formValues.dob.label"
                  :editable="true" /></v-col
            ></template>
            <template v-if="formValues.weight">
              <v-col cols="12" sm="3" class="pl-4">
                <v-text-field class="ml-4" outlined dense>
                  <template slot="prepend">
                    <template v-if="!formValues.weight.active">
                      <v-icon
                        @click="formValues.weight.active = true"
                        color="error"
                        >mdi-close</v-icon
                      >
                    </template>
                    <template v-else>
                      <v-icon
                        @click="formValues.weight.active = false"
                        color="success"
                        >mdi-check</v-icon
                      >
                      <v-icon @click="toEdit(formValues.weight)" color="info"
                        >mdi-pencil</v-icon
                      >
                    </template>
                  </template>
                  <template slot="label">
                    <span>{{ formValues.weight.label }}</span>
                  </template>
                </v-text-field></v-col
              >
            </template>
            <template v-if="formValues.height">
              <v-col
                class="d-flex flex-column align-center heightfield"
                cols="12"
                sm="6"
              >
                <label>
                  <template v-if="!formValues.height.active">
                    <v-icon
                      @click="formValues.height.active = true"
                      color="error"
                      >mdi-close</v-icon
                    >
                  </template>
                  <template v-else>
                    <v-icon
                      @click="formValues.height.active = false"
                      color="success"
                      >mdi-check</v-icon
                    >
                    <v-icon @click="toEdit(formValues.height)" color="info"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  {{ formValues.height.label }}
                </label>
                <div class="d-flex mt-1">
                  <v-text-field
                    class="mx-1"
                    :label="formValues.height.label1"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    class="mx-1"
                    :label="formValues.height.label2"
                    outlined
                    dense
                  ></v-text-field>
                </div> </v-col
            ></template>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <template v-if="formValues.doctorChoise">
                <div class="field">
                  <v-autocomplete
                    outlined
                    rounded
                    :items="proceduresform"
                    item-text="name"
                    item-value="name"
                    dense
                  >
                    <template slot="prepend">
                      <template v-if="!formValues.doctorChoise.active">
                        <v-icon
                          @click="formValues.doctorChoise.active = true"
                          color="error"
                          >mdi-close</v-icon
                        >
                      </template>
                      <template v-else>
                        <v-icon
                          @click="formValues.doctorChoise.active = false"
                          color="success"
                          >mdi-check</v-icon
                        >
                        <v-icon
                          @click="toEdit(formValues.doctorChoise)"
                          color="info"
                          >mdi-pencil</v-icon
                        >
                      </template>
                    </template>
                    <template slot="label">
                      <span>{{ formValues.doctorChoise.label }}</span>
                    </template>
                  </v-autocomplete>
                </div>
              </template></v-col
            >
            <v-col cols="12" sm="6">
              <template v-if="formValues.procedureInterest">
                <div class="field">
                  <v-autocomplete
                    outlined
                    rounded
                    :items="proceduresform"
                    item-text="name"
                    item-value="name"
                    dense
                  >
                    <template slot="prepend">
                      <template v-if="!formValues.procedureInterest.active">
                        <v-icon
                          @click="formValues.procedureInterest.active = true"
                          color="error"
                          >mdi-close</v-icon
                        >
                      </template>
                      <template v-else>
                        <v-icon
                          @click="formValues.procedureInterest.active = false"
                          color="success"
                          >mdi-check</v-icon
                        >
                        <v-icon
                          @click="toEdit(formValues.procedureInterest)"
                          color="info"
                          >mdi-pencil</v-icon
                        >
                      </template>
                    </template>
                    <template slot="label">
                      <span>{{ formValues.procedureInterest.label }}</span>
                    </template>
                  </v-autocomplete>
                </div>
              </template></v-col
            >
          </v-row>

          <template v-if="formValues.medicalCondition">
            <div class="field">
              <v-text-field outlined rounded dense>
                <template slot="prepend">
                  <template v-if="!formValues.medicalCondition.active">
                    <v-icon
                      @click="formValues.medicalCondition.active = true"
                      color="error"
                      >mdi-close</v-icon
                    >
                  </template>
                  <template v-else>
                    <v-icon
                      @click="formValues.medicalCondition.active = false"
                      color="success"
                      >mdi-check</v-icon
                    >
                    <v-icon
                      @click="toEdit(formValues.medicalCondition)"
                      color="info"
                      >mdi-pencil</v-icon
                    >
                  </template>
                </template>
                <template slot="label">
                  <span>{{ formValues.medicalCondition.label }}</span>
                </template>
              </v-text-field>
            </div>
          </template>
          <template v-if="formValues.medications">
            <div class="field">
              <v-text-field outlined rounded dense>
                <template slot="prepend">
                  <template v-if="!formValues.medications.active">
                    <v-icon
                      @click="formValues.medications.active = true"
                      color="error"
                      >mdi-close</v-icon
                    >
                  </template>
                  <template v-else>
                    <v-icon
                      @click="formValues.medications.active = false"
                      color="success"
                      >mdi-check</v-icon
                    >
                    <v-icon @click="toEdit(formValues.medications)" color="info"
                      >mdi-pencil</v-icon
                    >
                  </template>
                </template>
                <template slot="label">
                  <span>{{ formValues.medications.label }}</span>
                </template>
              </v-text-field>
            </div></template
          >
          <template v-if="formValues.alergies">
            <div class="field">
              <v-textarea outlined auto-grow rows="1" row-height="45">
                <template slot="prepend">
                  <template v-if="!formValues.alergies.active">
                    <v-icon
                      @click="formValues.alergies.active = true"
                      color="error"
                      >mdi-close</v-icon
                    >
                  </template>
                  <template v-else>
                    <v-icon
                      @click="formValues.alergies.active = false"
                      color="success"
                      >mdi-check</v-icon
                    >
                    <v-icon @click="toEdit(formValues.alergies)" color="info"
                      >mdi-pencil</v-icon
                    >
                  </template>
                </template>
                <template slot="label">
                  <span>{{ formValues.alergies.label }}</span>
                </template>
              </v-textarea>
            </div></template
          >
          <template v-if="formValues.bloodClots">
            <div class="field">
              <label>{{ formValues.bloodClots.label }}</label>
              <v-textarea outlined auto-grow rows="1" row-height="65" value="">
                <template slot="prepend">
                  <template v-if="!formValues.bloodClots.active">
                    <v-icon
                      @click="formValues.bloodClots.active = true"
                      color="error"
                      >mdi-close</v-icon
                    >
                  </template>
                  <template v-else>
                    <v-icon
                      @click="formValues.bloodClots.active = false"
                      color="success"
                      >mdi-check</v-icon
                    >
                    <v-icon @click="toEdit(formValues.bloodClots)" color="info"
                      >mdi-pencil</v-icon
                    >
                  </template>
                </template>
                <template slot="label">
                  <span>{{ formValues.bloodClots.label2 }}</span>
                </template>
              </v-textarea>
            </div></template
          >
          <template v-if="formValues.previousSurgery">
            <div class="field">
              <label for="">{{ formValues.previousSurgery.label }}</label>
              <v-radio-group v-model="previousProc" row>
                <template slot="prepend">
                  <template v-if="!formValues.previousSurgery.active">
                    <v-icon
                      @click="formValues.previousSurgery.active = true"
                      color="error"
                      >mdi-close</v-icon
                    >
                  </template>
                  <template v-else>
                    <v-icon
                      @click="formValues.previousSurgery.active = false"
                      color="success"
                      >mdi-check</v-icon
                    >
                    <v-icon
                      @click="toEdit(formValues.previousSurgery)"
                      color="info"
                      >mdi-pencil</v-icon
                    >
                  </template>
                </template>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
              <template
                v-if="previousProc && formValues.previousSurgery.active"
              >
                <v-text-field outlined rounded dense>
                  <template slot="label">
                    <span>{{ formValues.previousSurgery.label2 }}</span>
                  </template>
                </v-text-field>
              </template>
            </div></template
          >

          <v-row class="field justify-space-between">
            <template v-if="formValues.buttshots">
              <v-col cols="12" sm="6">
                <v-switch inset>
                  <template slot="prepend">
                    <template v-if="!formValues.buttshots.active">
                      <v-icon
                        @click="formValues.buttshots.active = true"
                        color="error"
                        >mdi-close</v-icon
                      >
                    </template>
                    <template v-else>
                      <v-icon
                        @click="formValues.buttshots.active = false"
                        color="success"
                        >mdi-check</v-icon
                      >
                      <v-icon @click="toEdit(formValues.buttshots)" color="info"
                        >mdi-pencil</v-icon
                      >
                    </template>
                  </template>
                  <template slot="label">
                    <span>{{ formValues.buttshots.label }}</span>
                  </template></v-switch
                >
              </v-col>
            </template>
            <template v-if="formValues.children">
              <v-col cols="12" sm="6">
                <v-switch inset>
                  <template slot="prepend">
                    <template v-if="!formValues.children.active">
                      <v-icon
                        @click="formValues.children.active = true"
                        color="error"
                        >mdi-close</v-icon
                      >
                    </template>
                    <template v-else>
                      <v-icon
                        @click="formValues.children.active = false"
                        color="success"
                        >mdi-check</v-icon
                      >
                      <v-icon @click="toEdit(formValues.children)" color="info"
                        >mdi-pencil</v-icon
                      >
                    </template>
                  </template>
                  <template slot="label">
                    <span>{{ formValues.children.label }}</span>
                  </template></v-switch
                >
              </v-col>
            </template>

            <template v-if="formValues.smoke">
              <v-col cols="12" sm="6">
                <v-switch inset>
                  <template slot="prepend">
                    <template v-if="!formValues.smoke.active">
                      <v-icon
                        @click="formValues.smoke.active = true"
                        color="error"
                        >mdi-close</v-icon
                      >
                    </template>
                    <template v-else>
                      <v-icon
                        @click="formValues.smoke.active = false"
                        color="success"
                        >mdi-check</v-icon
                      >
                      <v-icon @click="toEdit(formValues.smoke)" color="info"
                        >mdi-pencil</v-icon
                      >
                    </template>
                  </template>
                  <template slot="label">
                    <span>{{ formValues.smoke.label }}</span>
                  </template>
                </v-switch>
              </v-col>
            </template>
            <template v-if="formValues.alcohol">
              <v-col cols="12" sm="6">
                <v-switch inset>
                  <template slot="prepend">
                    <template v-if="!formValues.alcohol.active">
                      <v-icon
                        @click="formValues.alcohol.active = true"
                        color="error"
                        >mdi-close</v-icon
                      >
                    </template>
                    <template v-else>
                      <v-icon
                        @click="formValues.alcohol.active = false"
                        color="success"
                        >mdi-check</v-icon
                      >
                      <v-icon @click="toEdit(formValues.alcohol)" color="info"
                        >mdi-pencil</v-icon
                      >
                    </template>
                  </template>
                  <template slot="label">
                    <span>{{ formValues.alcohol.label }}</span>
                  </template>
                </v-switch>
              </v-col>
            </template>
            <v-col cols="12" sm="6">
              <v-switch inset v-model="otherSubs">
                <template slot="prepend">
                  <template v-if="!formValues.other.active">
                    <v-icon
                      @click="formValues.other.active = true"
                      color="error"
                      >mdi-close</v-icon
                    >
                  </template>
                  <template v-else>
                    <v-icon
                      @click="formValues.other.active = false"
                      color="success"
                      >mdi-check</v-icon
                    >
                    <v-icon @click="toEdit(formValues.other)" color="info"
                      >mdi-pencil</v-icon
                    >
                  </template>
                </template>
                <template slot="label">
                  <span>{{ formValues.other.label }}</span>
                </template>
              </v-switch>
            </v-col>

            <template v-if="otherSubs">
              <v-col cols="12" sm="6">
                <v-textarea outlined auto-grow rows="1" row-height="65">
                  <template slot="label">
                    <span>{{ formValues.other.label2 }}</span>
                  </template></v-textarea
                >
              </v-col>
            </template>
          </v-row>
          <div class="field d-flex justify-space-between">
            <v-select :items="desireSurgery" item-value="value">
              <template slot="prepend">
                <template v-if="!formValues.desireSurgeryDate.active">
                  <v-icon
                    @click="formValues.desireSurgeryDate.active = true"
                    color="error"
                    >mdi-close</v-icon
                  >
                </template>
                <template v-else>
                  <v-icon
                    @click="formValues.desireSurgeryDate.active = false"
                    color="success"
                    >mdi-check</v-icon
                  >
                  <v-icon
                    @click="toEdit(formValues.desireSurgeryDate)"
                    color="info"
                    >mdi-pencil</v-icon
                  >
                </template>
              </template>
              <template slot="label">
                <span>{{ formValues.desireSurgeryDate.label }}</span>
              </template>
              <template v-slot:item="{ item }">
                <v-chip small dark :class="item.color">
                  {{ item.label }}
                </v-chip>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip small dark :class="item.color">
                  {{ item.label }}
                </v-chip>
              </template>
            </v-select>
            <v-spacer></v-spacer>
            <v-select :items="references" item-text="name" item-value="name">
              <template slot="prepend">
                <template v-if="!formValues.source.active">
                  <v-icon @click="formValues.source.active = true" color="error"
                    >mdi-close</v-icon
                  >
                </template>
                <template v-else>
                  <v-icon
                    @click="formValues.source.active = false"
                    color="success"
                    >mdi-check</v-icon
                  >
                  <v-icon @click="toEdit(formValues.source)" color="info"
                    >mdi-pencil</v-icon
                  >
                </template>
              </template>
              <template slot="label">
                <p class="labels1">{{ formValues.source.label }}</p>
              </template>
            </v-select>
          </div>
          <div class="field d-flex justify-space-between">
            <div class="col-sm-6 notes">
              <v-textarea outlined auto-grow rows="1" row-height="65">
                <template slot="prepend">
                  <template v-if="!formValues.details.active">
                    <v-icon
                      @click="formValues.details.active = true"
                      color="error"
                      >mdi-close</v-icon
                    >
                  </template>
                  <template v-else>
                    <v-icon
                      @click="formValues.details.active = false"
                      color="success"
                      >mdi-check</v-icon
                    >
                    <v-icon @click="toEdit(formValues.details)" color="info"
                      >mdi-pencil</v-icon
                    >
                  </template>
                </template>
                <template slot="label">
                  <span>{{ formValues.details.label }}</span>
                </template></v-textarea
              >
            </div>
            <div class="col-sm-6 files">
              <template>
                <v-file-input multiple prepend-icon="mdi-paperclip">
                  <template slot="append">
                    <template v-if="!formValues.photo.active">
                      <v-icon
                        @click="formValues.photo.active = true"
                        color="error"
                        >mdi-close</v-icon
                      >
                    </template>
                    <template v-else>
                      <v-icon
                        @click="formValues.photo.active = false"
                        color="success"
                        >mdi-check</v-icon
                      >
                      <v-icon @click="toEdit(formValues.photo)" color="info"
                        >mdi-pencil</v-icon
                      >
                    </template>
                  </template>
                  <template slot="label">
                    <span>{{ formValues.photo.label2 }}</span>
                  </template>
                </v-file-input>
              </template>
            </div>
          </div>
          <div class="field">
            <label for="">{{ formValues.besttime.label }}</label>
            <p class="text--secondary">{{ formValues.besttime.label2 }}</p>
            <v-text-field rounded outlined dense>
              <template slot="prepend">
                <template v-if="!formValues.besttime.active">
                  <v-icon
                    @click="formValues.besttime.active = true"
                    color="error"
                    >mdi-close</v-icon
                  >
                </template>
                <template v-else>
                  <v-icon
                    @click="formValues.besttime.active = false"
                    color="success"
                    >mdi-check</v-icon
                  >
                  <v-icon @click="toEdit(formValues.besttime)" color="info"
                    >mdi-pencil</v-icon
                  >
                </template>
              </template>
            </v-text-field>
          </div>
          <div :class="emailMe ? 'emailresp field' : 'field'">
            <v-checkbox v-model="emailMe" label="">
              <template slot="prepend">
                <template v-if="!formValues.emailme.active">
                  <v-icon
                    @click="formValues.emailme.active = true"
                    color="error"
                    >mdi-close</v-icon
                  >
                </template>
                <template v-else>
                  <v-icon
                    @click="formValues.emailme.active = false"
                    color="success"
                    >mdi-check</v-icon
                  >
                  <v-icon @click="toEdit(formValues.emailme)" color="info"
                    >mdi-pencil</v-icon
                  >
                </template>
              </template>
              <template slot="label">
                <span>{{ formValues.emailme.label }}</span>
              </template>
            </v-checkbox>
            <template v-if="emailMe">
              <p class="text--secondary">
                {{ formValues.emailme.label2 }}
              </p>
            </template>
          </div>
          <!--   <template>
              <vue-recaptcha
                sitekey="6LcVUdkiAAAAAMimBkK84Id-4Z_CoNiK2tUwyAcb"
              ></vue-recaptcha>
            </template> -->
        </v-form>
      </template>
      <v-dialog
        v-model="dialogEdit"
        persistent
        :overlay="false"
        max-width="450px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title primary-title> Edit Field </v-card-title>
          <v-card-text>
            <template v-if="itemEdit != null">
              <v-checkbox label="Active" v-model="itemEdit.active"></v-checkbox>
              <template v-if="itemEdit.label">
                <v-text-field
                  label="Label"
                  v-model="itemEdit.label"
                ></v-text-field>
              </template>
              <template v-if="itemEdit.label1">
                <v-text-field
                  label="Label1"
                  v-model="itemEdit.label1"
                ></v-text-field>
              </template>
              <template v-if="itemEdit.label2">
                <v-text-field
                  label="Label2"
                  v-model="itemEdit.label2"
                ></v-text-field>
              </template>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="cancelEdit" text>cancel</v-btn>
            <v-btn color="info" @click="confirmEdit" text>confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { desireSurgery } from "@/utils/consts";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { mapActions, mapState } from "vuex";
import { notifyError, notifySuccess } from "@/components/Notification";
import { axiosBase, getAPI } from "@/api/axios-base";
export default {
  name: "form-config",
  props: {
    indexFormconfig: {
      type: Number,
      default: () => 1,
    },
  },
  components: {
    MaDatePicker,
  },
  data() {
    return {
      exist: true,
      isDefault: false,
      idForm: null,
      desireSurgery,
      emailMe: false,
      sources: [],
      windowsstep: 1,
      windowslength: 5,
      otherSubs: false,
      proceduresform: [],
      isMale: false,
      birthDate: null,
      dialogCancel: false,
      dialogSave: false,
      dialogEdit: false,
      itemEdit: null,
      previousProc: false,
      formConfig: null,
      url: "no_name",
      formValues: {
        sourceDefault: null,
        dob: {
          name: "dob",
          label: "Date of Birth",
          active: true,
        },
        weight: {
          name: "weight",
          label: "Weight (lbs)",
          active: true,
        },
        height: {
          name: "height",
          label: "Height",
          label1: "Feet",
          label2: "Inch",
          active: true,
        },
        gender: {
          name: "gender",
          label: "Female",
          label1: "Male",

          active: true,
        },
        doctorChoise: {
          name: "doctorChoise",
          label: "Doctor of Choice",
          active: true,
        },
        procedureInterest: {
          name: "procedureInterest",
          label: "Procedure of Interest",
          active: true,
        },

        medicalCondition: {
          name: "medicalCondition",
          label: "Do you have any medical conditions?",
          active: true,
        },
        medications: {
          name: "medications",
          label: "Do you take any medications?",
          active: true,
        },
        alergies: {
          name: "alergies",
          label: "Alergies",
          active: true,
        },
        bloodClots: {
          name: "bloodClots",
          label: "Have you ever had any blood clots/ blood clotting?",
          label2: "If yes, please describe the situation and when it happened",
          active: true,
        },
        previousSurgery: {
          name: "previousSurgery",
          label: "Any procedures or surgeries in the last 3 months?",
          label2: "Please specify.",
          active: true,
        },

        buttshots: {
          name: "buttshots",
          label: "Do you have butt shots/ injections?",
          active: true,
        },
        children: {
          name: "children",
          label: "Do you have Children?",
          active: true,
        },
        smoke: {
          name: "smoke",
          label: "Do you smoke or use any nicotine products?",
          active: true,
        },
        alcohol: {
          name: "alcohol",
          label: "Do you drink alcohol?",
          active: true,
        },
        other: {
          name: "other",
          label: "Other Substances",
          label2: "Substances",
          active: true,
        },
        desireSurgeryDate: {
          name: "desireSurgeryDate",
          label: "Desired Surgery Date",
          active: true,
        },
        source: {
          name: "source",
          label: "Please tell us how you heard about us",
          active: true,
        },
        details: {
          name: "details",
          label: "Notes",
          active: true,
        },
        photo: {
          name: "photo",
          label: "Photos",
          label2: "Attach files",
          active: true,
        },
        besttime: {
          name: "besttime",
          label: "Best time to call",
          label2: "(office hours 9am- 6pm, M-F)",
          active: true,
        },
        emailme: {
          name: "emailme",
          label: "Email me a copy of my responses.",
          label2:
            "We’ll only use your email address to send you your responses. Once you submit your responses, you’ll be sent to our sign-up page.",
          active: true,
        },
      },
    };
  },
  computed: {
    ...mapState(["defaultFormId", "configForm"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),
  },
  methods: {
    ...mapActions(["actAddSettings", "actUpdateSettings", "actGeneralConfig"]),
    ...mapActions("crmSettingsModule", ["actListReferences"]),
    toEdit(item) {
      this.itemEdit = Object.assign({}, item);
      this.dialogEdit = true;
    },
    cancelEdit() {
      this.itemEdit = null;
      this.dialogEdit = false;
    },
    confirmEdit() {
      this.dialogEdit = false;
      this.formValues[this.itemEdit.name] = this.itemEdit;
    },
    resetForm() {
      this.formValues = {
        sourceDefault: null,
        dob: {
          name: "dob",
          label: "Date of Birth",
          active: true,
        },
        weight: {
          name: "weight",
          label: "Weight (lbs)",
          active: true,
        },
        height: {
          name: "height",
          label: "Height",
          label1: "Feet",
          label2: "Inch",
          active: true,
        },
        gender: {
          name: "gender",
          label: "Female",
          label1: "Male",

          active: true,
        },
        doctorChoise: {
          name: "doctorChoise",
          label: "Doctor of Choice",
          active: true,
        },
        procedureInterest: {
          name: "procedureInterest",
          label: "Procedure of Interest",
          active: true,
        },
        medicalCondition: {
          name: "medicalCondition",
          label: "Do you have any medical conditions?",
          active: true,
        },
        medications: {
          name: "medications",
          label: "Do you take any medications?",
          active: true,
        },
        alergies: {
          name: "alergies",
          label: "Alergies",
          active: true,
        },
        bloodClots: {
          name: "bloodClots",
          label: "Have you ever had any blood clots/ blood clotting?",
          label2: "If yes, please describe the situation and when it happened",
          active: true,
        },
        previousSurgery: {
          name: "previousSurgery",
          label: "Any procedures or surgeries in the last 3 months?",
          label2: "Please specify.",
          active: true,
        },

        buttshots: {
          name: "buttshots",
          label: "Do you have butt shots/ injections?",
          active: true,
        },
        children: {
          name: "children",
          label: "Do you have Children?",
          active: true,
        },
        smoke: {
          name: "smoke",
          label: "Do you smoke or use any nicotine products?",
          active: true,
        },
        alcohol: {
          name: "alcohol",
          label: "Do you drink alcohol?",
          active: true,
        },
        other: {
          name: "other",
          label: "Other Substances",
          label2: "Substances",
          active: true,
        },
        desireSurgeryDate: {
          name: "desireSurgeryDate",
          label: "Desired Surgery Date",
          active: true,
        },
        source: {
          name: "source",
          label: "Please tell us how you heard about us",
          active: true,
        },
        details: {
          name: "details",
          label: "Notes",
          active: true,
        },
        photo: {
          name: "photo",
          label: "Photos",
          label2: "Attach files",
          active: true,
        },
        besttime: {
          name: "besttime",
          label: "Best time to call",
          label2: "(office hours 9am- 6pm, M-F)",
          active: true,
        },
        emailme: {
          name: "emailme",
          label: "Email me a copy of my responses.",
          label2:
            "We’ll only use your email address to send you your responses. Once you submit your responses, you’ll be sent to our sign-up page.",
          active: true,
        },
      };
      this.dialogCancel = false;
    },

    /*  getNomenclators() {
      axiosBase.get("/patient/getNomenclatorsForRegisterLead").then((res) => {
        this.sources = res.data.howKnows;
      });
    }, */

    updateForm() {
      this.formValues = {
        sourceDefault: this.formValues.sourceDefault
          ? this.formValues.sourceDefault
          : null,
        dob: this.formValues.dob
          ? this.formValues.dob
          : {
              name: "dob",
              label: "Date of Birth",
              active: true,
            },
        weight: this.formValues.weight
          ? this.formValues.weight
          : {
              name: "weight",
              label: "Weight (lbs)",
              active: true,
            },
        height: this.formValues.height
          ? this.formValues.height
          : {
              name: "height",
              label: "Height",
              label1: "Feet",
              label2: "Inch",
              active: true,
            },
        gender: this.formValues.gender
          ? this.formValues.gender
          : {
              name: "gender",
              label: "Female",
              label1: "Male",

              active: true,
            },
        doctorChoise: this.formValues.doctorChoise
          ? this.formValues.doctorChoise
          : {
              name: "doctorChoise",
              label: "Doctor of Choice",
              active: true,
            },
        procedureInterest: this.formValues.procedureInterest
          ? this.formValues.procedureInterest
          : {
              name: "procedureInterest",
              label: "Procedure of Interest",
              active: true,
            },
        medicalCondition: this.formValues.medicalCondition
          ? this.formValues.medicalCondition
          : {
              name: "medicalCondition",
              label: "Do you have any medical conditions?",
              active: true,
            },
        medications: this.formValues.medications
          ? this.formValues.medications
          : {
              name: "medications",
              label: "Do you take any medications?",
              active: true,
            },
        alergies: this.formValues.alergies
          ? this.formValues.alergies
          : {
              name: "alergies",
              label: "Alergies",
              active: true,
            },
        bloodClots: this.formValues.bloodClots
          ? this.formValues.bloodClots
          : {
              name: "bloodClots",
              label: "Have you ever had any blood clots/ blood clotting?",
              label2:
                "If yes, please describe the situation and when it happened",
              active: true,
            },
        previousSurgery: this.formValues.previousSurgery
          ? this.formValues.previousSurgery
          : {
              name: "previousSurgery",
              label: "Any procedures or surgeries in the last 3 months?",
              label2: "Please specify.",
              active: true,
            },

        buttshots: this.formValues.buttshots
          ? this.formValues.buttshots
          : {
              name: "buttshots",
              label: "Do you have butt shots/ injections?",
              active: true,
            },
        children: this.formValues.children
          ? this.formValues.children
          : {
              name: "children",
              label: "Do you have Children?",
              active: true,
            },
        smoke: this.formValues.smoke
          ? this.formValues.smoke
          : {
              name: "smoke",
              label: "Do you smoke or use any nicotine products?",
              active: true,
            },
        alcohol: this.formValues.alcohol
          ? this.formValues.alcohol
          : {
              name: "alcohol",
              label: "Do you drink alcohol?",
              active: true,
            },
        other: this.formValues.other
          ? this.formValues.other
          : {
              name: "other",
              label: "Other Substances",
              label2: "Substances",
              active: true,
            },
        desireSurgeryDate: this.formValues.desireSurgeryDate
          ? this.formValues.desireSurgeryDate
          : {
              name: "desireSurgeryDate",
              label: "Desired Surgery Date",
              active: true,
            },
        source: this.formValues.source
          ? this.formValues.source
          : {
              name: "source",
              label: "Please tell us how you heard about us",
              active: true,
            },
        details: this.formValues.details
          ? this.formValues.details
          : {
              name: "details",
              label: "Notes",
              active: true,
            },
        photo: this.formValues.photo
          ? this.formValues.photo
          : {
              name: "photo",
              label: "Photos",
              label2: "Attach files",
              active: true,
            },
        besttime: this.formValues.besttime
          ? this.formValues.besttime
          : {
              name: "besttime",
              label: "Best time to call",
              label2: "(office hours 9am- 6pm, M-F)",
              active: true,
            },
        emailme: this.formValues.emailme
          ? this.formValues.emailme
          : {
              name: "emailme",
              label: "Email me a copy of my responses.",
              label2:
                "We’ll only use your email address to send you your responses. Once you submit your responses, you’ll be sent to our sign-up page.",
              active: true,
            },
      };
      const settings = {
        active: this.formConfig.active,
        name: `form_config-${this.indexFormconfig}`,
        description: `Form configurations #url:${this.url}`,
        value: JSON.stringify(this.formValues),
        isPublic: this.formConfig.isPublic,
        uuid: this.formConfig.uuid,
      };
      this.actUpdateSettings(settings);
    },
    async useForm() {
      const body = JSON.stringify(this.formValues);
      this.dialogSave = false;

      if (this.defaultFormId != null && this.defaultFormId != undefined) {
        const settings = {
          name: `form_config-default`,
          description: `Form configurations Default`,
          value: this.idForm,
          isPublic: this.defaultFormId.isPublic,
          uuid: this.defaultFormId.uuid,
          active: this.defaultFormId.active,
        };
        await this.actUpdateSettings(settings);
        this.actthisForm();
      } else {
        const settings = {
          name: `form_config-default`,
          description: `Form configurations Default`,
          value: this.idForm,
          isPublic: true,
        };
        getAPI
          .post("/setting/create", settings)
          .then(() => {
            notifySuccess("This form is now default");
            this.exist = true;
            this.actthisForm();
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },
    async saveNewForm() {
      const body = JSON.stringify(this.formValues);
      this.dialogSave = false;
      const settings = {
        name: `form_config-${this.indexFormconfig}`,
        description: `Form configurations #url:${this.url}`,
        value: body,
        isPublic: true,
      };
      getAPI
        .post("/setting/create", settings)
        .then(() => {
          notifySuccess("Form Configurarions has been save");
          this.exist = true;
          this.actthisForm();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    async actthisForm() {
      await this.actGeneralConfig();
      this.initForm();
    },

    initForm() {
      if (this.configForm.length != 0) {
        const temp = this.configForm.find(
          (cf) => cf.indexConfig == this.indexFormconfig
        );

        if (temp) {
          this.formConfig = temp;
          this.idForm = temp.uuid;

          if (temp.url) {
            this.url = temp.url;
          }
          this.formValues = JSON.parse(temp.value);

          if (this.defaultFormId != null && this.defaultFormId != undefined) {
            const df = this.defaultFormId.value;
            if (df == this.idForm) {
              this.isDefault = true;
            }
          }
        } else {
          this.resetForm();
          this.exist = false;
        }
      } else {
        this.resetForm();
        this.exist = false;
      }
    },
  },
  async mounted() {
    await this.actListReferences();
    this.initForm();
  },
};
</script>
<style lang="scss" scoped>
.form_lead {
  max-width: 45rem;

  margin: 25px auto;
}
.selectsource {
  max-width: 140px !important;
  max-height: 40px !important;
}
.form_leadt {
  max-width: 45rem;
  display: block;
  margin: 25px auto;
  padding: 0 15px;
}
.emailresp {
  background-color: #ffeab6;
}
.field {
  padding: 0 15px;
}
.heightfield {
  margin-top: -27px !important;
}
.genderfield {
  margin-top: -20px !important;
}
</style>
